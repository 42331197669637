import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SigninComponent } from './common/signin/signin.component';
import { AuthGuardService } from './services/auth/auth-guard.service';
import { ChangePasswordComponent } from './common/change-password/change-password.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'signin',
    pathMatch: 'full',
  },
  {
    path: 'signin',
    component: SigninComponent,
  },
  {
    path: 'change-password',
    component: ChangePasswordComponent,
  },
  {
    path: 'hubAdmin',
    canActivate: [AuthGuardService],
    loadChildren: () => import('./hubadmin/hubadmin.module').then(m => m.HubadminModule)
  },
  {
    path: 'agent',
    canActivate: [AuthGuardService],
    loadChildren: () => import('./agent/agent.module').then(m => m.AgentModule)
  },
  {
    path: 'segment',
    canActivate: [AuthGuardService],
    loadChildren: () => import('./segment/segment.module').then(m => m.SegmentModule)
  },
  {
    path: 'approver',
    canActivate: [AuthGuardService],
    loadChildren: () => import('./approver/approver.module').then(m => m.ApproverModule)
  },
  {
    path: 'reporting-dashboard',
    canActivate: [AuthGuardService],
    loadChildren: () => import('./reporting-dashboard/reporting-dashboard.module').then(m => m.ReportingDashboardModule)
  },
  {
    path: 'supply-chain',
    canActivate: [AuthGuardService],
    loadChildren: () => import('./supply-chain/supply-chain.module').then(m => m.SupplyChainModule)
  },
  {
    path: 'coupon-supply',
    canActivate: [AuthGuardService],
    loadChildren: () => import('./coupon-supply/coupon-supply.module').then(m => m.CouponSupplyModule)
  },

  {
    path: 'factory-store',
    canActivate: [AuthGuardService],
    loadChildren: () => import('./factory-store/factory-store.module').then(m => m.FactoryStoreModule)
  },
  {
    path: 'cap-supplier',
    canActivate: [AuthGuardService],
    loadChildren: () => import('./cap-supplier/cap-supplier.module').then(m => m.CapSupplierModule)
  },
  {
    path: 'team-lead',
    canActivate: [AuthGuardService],
    loadChildren: () => import('./team-lead/team-lead.module').then(m => m.TeamLeadModule)
  },
  {
    path: 'scanner-agents',
    canActivate: [AuthGuardService],
    loadChildren: () => import('./scanner-agent/scanner-agent.module').then(m => m.ScannerAgentModule)
  },
  {
    path: 'call-center',
    canActivate: [AuthGuardService],
    loadChildren: () => import('./call-center/call-center.module').then(m => m.CallCenterModule)
  },
  {
    path: 'finance-reporting',
    canActivate: [AuthGuardService],
    loadChildren: () => import('./finance-reporting/finance-reporting.module').then(m => m.FinanceReportingModule)
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    useHash: true
  })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
