import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthenticationCheckService } from 'src/app/services/auth/authentication-check.service';
import { ApiConfiguration } from 'src/app/services/http/api-configuration';
import { ApiService } from 'src/app/services/http/api.service';
import { ToastClientService } from 'src/app/services/toast/toast-client.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {


  passwordForm;


  submitted = false;
  inputPasswordType1: string = "password"
  inputPasswordType2: string = "password"

  constructor(
    private apiService: ApiService,
    private apiConfig: ApiConfiguration,
    private router: Router,
    private formBuilder: FormBuilder,
    private auth: AuthenticationCheckService,
    private toast: ToastClientService,

  ) {


    let user_name = localStorage.getItem("user_name");
    this.checkLogin();
    this.passwordForm = formBuilder.group({
      username: new FormControl(user_name, [Validators.required, Validators.maxLength(25)]),
      old_password: new FormControl("", []),
      new_password: new FormControl("", [Validators.required, Validators.maxLength(25)]),
      confirm_password: new FormControl("", [Validators.required, Validators.maxLength(25)]),
    });


  }

  ngOnInit() {

  }

  get f() {
    return this.passwordForm.controls;
  }


  toggleShowPassword1() {
    this.inputPasswordType1 == 'password' ? this.inputPasswordType1 = 'text' : this.inputPasswordType1 = 'password'
    setTimeout(() => { this.inputPasswordType1 = 'password' }, 2000)
  }


  toggleShowPassword2() {
    this.inputPasswordType2 == 'password' ? this.inputPasswordType2 = 'text' : this.inputPasswordType2 = 'password'
    setTimeout(() => { this.inputPasswordType2 = 'password' }, 2000)
  }


  onSubmitPassword(formData) {
    this.submitted = true;
    if (this.passwordForm.invalid) {
      return;
    } else {
      //console.log(formData)
      this.apiService.post(this.apiConfig.resetPassword, formData).subscribe(
        (res: any) => {
          if (res.status == 200) {
            this.toast.Success(res.body.data);
            this.router.navigateByUrl('/signin', { replaceUrl: true });
          }
        },
        (err) => {
          console.log("err", err);
        }
      );
    }



  }



  checkLogin() {
    let previousURL = localStorage.getItem("previousURL")
    let login = localStorage.getItem("login")
    let userRole: any = localStorage.getItem("user_role");
    let password_expiry_date_flag: any = localStorage.getItem("password_expiry_date_flag");
    //console.log("preee", previousURL);
    if (login == 'true' && password_expiry_date_flag == 0) {
      if (previousURL) {
        this.router.navigateByUrl(previousURL)
      } else {
        this.router.navigateByUrl('/signin', { replaceUrl: true });
      }

    }

  }







}
















